import type { FC } from 'react'
import { memo } from 'react'
import styled, { css } from 'styled-components'

import RNLogoSvg from '@rnPublic/images/logo/logo.svg'
import RNLogoShortSvg from '@rnPublic/images/logo/logoShort.svg'

type Props = {
  isShort?: boolean
}

const RailNinjaLogo: FC<Props> = ({ isShort }) => {
  return isShort ? <LogoShortStyled /> : <LogoStyled />
}

const commonStyles = css`
  height: 46px;
  color: ${p => p.theme.colors.backgroundContrast};

  & > g > path:last-child {
    fill: white;
  }
`

const LogoShortStyled = styled(RNLogoShortSvg)`
  ${commonStyles};
`

const LogoStyled = styled(RNLogoSvg)`
  ${commonStyles};
`

export default memo(RailNinjaLogo)
